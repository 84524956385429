<!-- =========================================================================================
  File Name: CourseView.vue
  Description: Course View page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/course/pixinvent
========================================================================================== -->

<template>
  <div id="page-course-view">

    <vs-alert :active.sync="course_not_found" color="danger" title="Course Not Found">
      <span>Course record with id: {{ $route.params.courseId }} not found. </span>
      <span>
        <span>Check </span>
        <router-link :to="{name:'page-course-list'}" class="text-inherit underline">All Courses</router-link>
      </span>
    </vs-alert>

    <div v-if="course_data" id="course-data">

      <div class="vx-row">

        <div class="vx-col lg:w-1/5 w-full">
          <vx-card class="mb-base" style="height:95%" title="Course Actions">
            <!-- Avatar -->
            <div class="vx-row">

              <div class="vx-col w-full flex">
                <vs-button class="w-full" color="success" icon="icon-book" icon-pack="feather"
                           style="height:2rem;" @click="initiateStudentAssignment">
                  Assign Student
                </vs-button>
              </div>


              <div class="vx-col w-full flex pt-3">
                <vs-button v-clipboard:copy="`https://rapidseminars.com/courses-course.html?&id=${this.course_data.id}`"
                           class="w-full" color="secondary" icon="icon-paperclip" icon-pack="feather"
                           style="height:2rem;">
                  Landing Link
                </vs-button>
              </div>

              <div class="vx-col w-full flex pt-3">
                <vs-button class="w-full" color="primary" icon="icon-refresh-cw" icon-pack="feather"
                           style="height:2rem;" @click="updateStatistics">
                  Update Stats
                </vs-button>
              </div>

              <div class="vx-col w-full flex pt-3">
                <vs-button :to="`/a/courses/${$route.params.courseId}/edit`" class="w-full" color="warning"
                           icon="icon-edit" icon-pack="feather" style="height:2rem;">
                  Edit
                </vs-button>
              </div>

              <!-- /Information - Col 2 -->
              <div class="vx-col w-full flex pt-3">
                <vs-button class="w-full" color="danger" icon="icon-archive" icon-pack="feather"
                           style="height:2rem;">
                  Delete
                </vs-button>
              </div>

            </div>
          </vx-card>

        </div>


        <div class="vx-col lg:w-2/5 w-full">
          <vx-card class="mb-base" style="height:95%" title="Extended Information">
            <table>
              <tr>
                <td class="font-semibold">Course Name</td>
                <td>{{ course_data.name }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Course Duration</td>
                <td>{{ course_data.statistics.total_seconds | duration }}</td>
              </tr>
              <vs-divider/>
              <tr>
                <td class="font-semibold">Course Author</td>
                <td>
                  <router-link :to="`/a/users/${course_data.author_id}`">
                    {{ author_name }}
                  </router-link>
                </td>
              </tr>
              <tr>
                <td class="font-semibold">Preview Video</td>
                <td>
                  <router-link :to="`/a/videos/${course_data.preview_id}`">
                    {{ video_name }}
                  </router-link>
                </td>
              </tr>
              <vs-divider/>
              <tr>
                <td class="font-semibold">Course Modules</td>
                <td>{{ course_data.module_ids.length }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Course Lessons</td>
                <td>{{ course_data.statistics.total_lessons }}</td>
              </tr>
            </table>

          </vx-card>
        </div>
        <div class="vx-col lg:w-2/5 w-full">
          <vx-card class="mb-base" style="height:95%" title="Course Thumbnail">
            <!-- Avatar -->
            <div class="vx-row">

              <!-- Avatar Col -->
              <div v-if="course_data.thumbnail_id" class="w-full vx-col">
                <div class="img-container">
                  <img :src="course_data.photoURL" class="rounded w-full"/>
                </div>
              </div>

            </div>
          </vx-card>
        </div>

      </div>


      <div class="vx-row">

        <!-- Avatar -->

        <div class="vx-col lg:w-3/5 w-full">
          <vx-card class="mb-base" title="Extended Information">

            <div class="vx-row">

              <vs-col class="flex-1 m-5 mb-0 mt-0">

                <vs-col vs-type="flex" w="12">
                  <vs-textarea v-model="course_data.description" disabled height="125px" label="Course Description"/>
                </vs-col>

                <vs-col vs-type="flex" w="12">
                  <vs-textarea v-model="course_data.introduction" disabled height="125px" label="Course Introduction"/>
                </vs-col>

              </vs-col>

            </div>

          </vx-card>
        </div>

        <div class="vx-col lg:w-2/5 w-full">
          <vx-card class="mb-base" title="Modules and Lessons">
            <v-tree ref='tree' :data='treeData' :nocheck="true" :tpl="tpl"/>
          </vx-card>
        </div>
      </div>


    </div>

    <vs-popup :active.sync="user_search_active" classContent="popup-example"
              title="Search for User by Name">

      <vs-table
        :data="user_search_results"
        :sst="true"
        max-items="5"
        search

        @search="handleUserSearch"
      >

        <template slot="header">
          <h3>Users</h3>
        </template>

        <template slot="thead">
          <vs-th>Name</vs-th>
          <vs-th>Email</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr v-for="(attach, index) in data" :key="attach.id">

            <vs-td :data="`${attach.first_name} ${attach.last_name}`">
              {{ `${attach.first_name} ${attach.last_name}` }}
            </vs-td>

            <vs-td :data="attach.email">
              {{ attach.email }}
            </vs-td>

            <vs-td>
              <vs-button color="primary" size="small" type="border" @click="handleUserSelected(attach.id)">
                Select
              </vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

    </vs-popup>

  </div>
</template>

<script>

import {VTree, VSelectTree} from 'vue-tree-halower';

export default {
  components: {
    VTree,
    VSelectTree,
  },
  data() {
    return {
      course_data: null,
      course_not_found: false,
      attributes: {},

      author_name: '',
      video_name: '',
      advertised_price: '',

      user_search_selected: [],
      user_search_active: false,
      user_search_results: [],

      treeData: [],
    };
  },
  methods: {
    openUserSearch() {
      this.user_search_active = true;
      return false;
    },
    handleUserSearch(term) {

      if (!term || term.length <= 3) return;


      this.$http.get(`users?email=${term}&amount=5`)
        .then(response => {

          if (response.data) {
            this.user_search_results = response.data.data;
          }

        });

    },
    handleUserSelected(id) {
      this.user_search_active = false;

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',

        title: 'Confirm Assignment',
        text: `Are you sure you want to give this student access to this course for 3 months?`,
        accept: () => {

          this.$vs.loading();

          this.$http.post(`billing/subscriptions`, {course_id: this.course_data.id, user_id: id})
            .then(response => {

              if (response.data) {
                this.$vs.loading.close();

                const payload = response.data.data;

                return this.$vs.notify({
                  color: 'success',
                  title: 'Subscription Created',
                  text: 'This student now has access to this course for 3 months.',
                });

              }

              throw new Error({response});
            })
            .catch(exception => {
              this.$vs.loading.close();

              let error = 'An unknown error occurred while loading the metadata of this course';
              if (exception.response) {
                error = exception.response.data.data.message;
              }

              return this.$vs.notify({
                title: 'Failed to load lesson author',
                text: error,
                color: 'danger',
              });

            });

        },
        acceptText: 'Assign',
      });

    },
    initiateStudentAssignment() {
      this.user_search_active = true;
    },

    updateStatistics() {

      this.$vs.loading();

      this.$http.post(`courses/${this.course_data.id}/statistics`)
        .then(response => {

          if (response.data) {
            this.$vs.loading.close();

            setTimeout(() => location.reload(), 2500);

            return this.$vs.notify({
              color: 'success',
              title: 'Refresh Course Statistics',
              text: 'Successfully updated the statistics for this course',
            });

          }

          throw new Error({response});
        })
        .catch(exception => {
          this.$vs.loading.close();

          let error = 'An unknown error occurred while loading the metadata of this course';
          if (exception.response) {
            error = exception.response.data.data.message;
          }

          return this.$vs.notify({
            title: 'Failed to load lesson author',
            text: error,
            color: 'danger',
          });

        });

    },

    tpl(...args) {
      let {0: node, 2: parent, 3: index} = args;
      let titleClass = node.selected ? 'node-title node-selected' : 'node-title';
      if (node.searched) titleClass += ' node-searched';
      // @formatter:off
      // noinspection JSAnnotator
      return <span>
        <span class={titleClass} domPropsInnerHTML={node.title} onClick={() => {
          this.$refs.tree.nodeSelected(node)
        }}></span>
        <button class="btn-delete text-danger border-none cursor-pointer px-2 py-1 rounded" onClick={() => {
          if (node.checked !== undefined) return
          let lesson = node.children === undefined;
          this.$router.push(`/a/${lesson ? 'lessons' : 'modules'}/${node.id}`)
        }}>view</button>
      </span>
      // @formatter:on
    },
    setPhotoUrl() {
      this.course_data.photoURL = `https://app-cdn.rapidseminars.com/thumbnails/${this.course_data.thumbnail_id ||
      'default'}.jpeg`;
    },
    loadMeta() {
      this.$http.get(`users/${this.course_data.author_id}`)
        .then(response => {

          if (response.data.data) {
            const payload = response.data.data;
            this.author_name = `${payload.first_name} ${payload.last_name}`;
          }

        })
        .catch(exception => {

          let error = 'An unknown error occurred while loading the metadata of this course';
          if (exception.response) {
            const {data} = error.response;
            if (data.data && data.data.message) {
              error = data.data.message;
            }
          }

          return this.$vs.notify({
            title: 'Failed to load lesson author',
            text: error,
            color: 'danger',
          });

        });

      if (this.course_data.advertised_price_id) {
        this.$http.get(
          `https://portal-api.rapid.education/v1/billing/products/unknown/prices/${this.course_data.advertised_price_id}`)
          .then(response => {

            if (response.data.data) {
              const payload = response.data.data;

              let amount = payload.amount;
              let intervalCount = payload.interval_count;
              let interval = payload.interval;

              let suffix = 'one-time';

              if (interval) {
                suffix = `/ ${intervalCount} ${interval}`;
              }

              this.advertised_price = `$${amount.toFixed(2)} ${suffix}`;
            }

          })
          .catch(exception => {

            let error = 'An unknown error occurred while loading the metadata of this course';
            if (exception.response) {
              error = exception.response.data.data.message;
            }

            return this.$vs.notify({
              title: 'Failed to load lesson author',
              text: error,
              color: 'danger',
            });

          });
      }

      if (this.course_data.preview_id) {
        this.$http.get(`videos/${this.course_data.preview_id}`)
          .then(response => {

            if (response.data.data) {
              const payload = response.data.data;
              this.video_name = payload.name;
            }

          })
          .catch(exception => {

            let error = 'An unknown error occurred while loading the metadata of this course';

            if (exception.response) {
              if (exception.response.data.error) {
                error = exception.response.data.error.description;
              }
            }

            return this.$vs.notify({
              title: 'Failed to load course preview video',
              text: error,
              color: 'danger',
            });

          });
      }

    },
    showError(exception) {

      let error = 'An unknown error occurred while modifying this course';
      if (exception.response) {
        error = exception.response.data.data.message;
      }

      return this.$vs.notify({
        title: 'Failed to update course',
        text: error,
        color: 'danger',
        position: 'top-right',
      });
    },
  },
  created() {

    const courseId = this.$route.params.courseId;

    this.$http.get(`courses/${courseId}/rich`)
      .then(response => {

        if (response.data.data) {
          this.course_data = response.data.data;
          this.course_data.service_name = this.course_data.service_id;
          this.author_name = this.course_data.author_id;
          this.advertised_price = this.course_data.advertised_price_id;

          let treeDataChildren = this.course_data.modules.map(module => {

            let treeDataLessons = module.lessons.map(lesson => {

              return {
                id: lesson.id,
                title: lesson.name,
                selDisabled: true,
              };

            });

            return {
              id: module.id,
              title: module.name,
              expanded: false,
              children: treeDataLessons,
              selDisabled: true,
            };

          });

          this.treeData = [
            {
              title: this.course_data.name,
              expanded: true,
              children: treeDataChildren,
              selDisabled: true,
            },
          ];

          this.setPhotoUrl();
          this.loadMeta();
        }

      })
      .catch(error => {

        if (error.response && error.response.status === 404) {
          this.course_not_found = true;
          return;
        }
        console.error(error);
      });
  },
};

</script>

<style lang="scss">
@import "@/assets/scss/vuexy/extraComponents/tree.scss";

button.btn-async {
  background: rgba(var(--vs-warning), 0.15);
}

button.btn-delete {
  background: rgba(var(--vs-danger), 0.15);
}
</style>

<style lang="scss">
#avatar-col {
  width: 15rem;
}

#page-course-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

// #account-info-col-1 {
//   // flex-grow: 1;
//   width: 30rem !important;
//   @media screen and (min-width:1200px) {
//     & {
//       flex-grow: unset !important;
//     }
//   }
// }


@media screen and (min-width: 1201px) and (max-width: 1211px),
only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

  // #account-manage-buttons {
  //   width: 12rem !important;
  //   flex-direction: column;

  //   > button {
  //     margin-right: 0 !important;
  //     margin-bottom: 1rem;
  //   }
  // }

}

</style>
